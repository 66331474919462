<template>
  <div class="flip-container-verify">
    <div class="login-screen">
      <span class="main-logo" :style="{ backgroundImage: `url(${config.SITE_LOGO_URL})` }"></span>
      <div class="login-block row middle-xs">
        <span
          class="loginimg"
          :style="{ backgroundImage: `url(${config.LOGIN_PAGE_IMAGE_URL})` }"
        ></span>
        <div class="login-info">
          <font-awesome-icon class="resend-email-icon" :icon="['far', 'envelope-open']" size="3x" />
          <p class="f-resend-title">Please verify your email</p>
          <p class="finfo">You're almost there! we sent an email to</p>
          <p class="femail">{{ email }}</p>
          <p class="finfo mb-15">
            Click the link in that email to complete the registration. If you don't see it, you may
            need to check your <span>spam</span> folder
          </p>
          <p class="finfo mb-10">Still can't find the email?</p>
          <p v-if="showEmailSent" class="sent-email-success">
            <font-awesome-icon class="success-check-icon" :icon="['fas', 'check']" size="1x" />
            Invite sent!
          </p>
          <k-button
            v-if="!showEmailSent"
            :size="4"
            label="Resend Invite"
            @click="sendEmailVerification"
          />
          <p class="finfo">Need help? <span class="link-color">Contact us</span></p>
        </div>

        <div v-if="error" class="error">Error: {{ error }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { isBlank } from 'adready-api/helpers/common';
import validationsMixin from 'adready-vue/components/mixins/validations-mixin';
import config from '~/config';

export default {
  name: 'VerifyEmail',

  mixins: [validationsMixin.events],

  props: {
    err: {
      required: false,
      type: String,
      default: undefined,
    },
    email: {
      required: true,
      type: String,
    },
    userId: {
      required: true,
      type: String,
    },
    isFromLogin: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      errorMsg: null,
      showEmailSent: false,
      config,
    };
  },
  computed: {
    error() {
      if (!isBlank(this.errorMsg)) {
        return this.errorMsg;
      }
      if (!isBlank(this.err)) {
        return this.err;
      }
      return null;
    },
  },

  methods: {
    async sendEmailVerification() {
      this.errorMsg = null;
      await axios
        .post(`${config.ADREADY_URL}/api/sendVerificationEmail?userId=${this.userId}`)
        .catch((error) => {
          this.errorMsg = 'failed to send verify mail';
          // eslint-disable-next-line no-console
          console.error(this.errorMsg, error);
          if (this.$sentry) {
            if (error._reported !== true) {
              this.$sentry.captureException(error);
              error._reported = true;
            }
          }
        });
      if (!this.errorMsg) {
        this.showEmailSent = true;
      }
      setTimeout(() => {
        this.showEmailSent = false;
      }, 3000);
    },
  },
};
</script>
<style lang="scss" scoped>
.flip-container-verify {
  background-color: #131619;
  width: 100vw !important;
  height: 100vh;
  margin-bottom: -40px;
  .login-block {
    max-width: 920px;
    background: #fff !important;
    & .loginimg {
      max-width: 500px;
      width: 500px;
      height: 480px;
    }
  }
  .login-info {
    text-align: center;
  }
  .login-info .f-resend-title {
    color: var(--defaultprimarycolor) !important;
    font-weight: 500;
    margin-top: 30px;
    font-size: 26px;
    margin-bottom: 30px;
  }
  .finfo {
    color: #8b8b8b;
    font-size: 14px;
    max-width: 355px;
    & span {
      font-weight: 600;
    }
    & span.link-color {
      color: var(--defaultprimarycolor) !important;
    }
  }
  .mb-15 {
    margin-bottom: 15px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .femail {
    color: #8b8b8b;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 14px;
  }
}
.sent-email-success {
  font-size: 16px;
  color: rgba(107, 207, 24);
  margin-bottom: 35px;
  height: 41px;
  .success-check-icon {
    color: rgba(107, 207, 24) !important;
    margin-left: 3px;
  }
}
::v-deep .login-info svg.svg-inline--fa {
  color: #daf0f3;
}
::v-deep .form-input .form-input-field {
  width: 100%;
  padding: 15px 20px;
  box-sizing: border-box;
  font-size: 12px;
  border: 1px solid #dadada !important;
  background-color: #fff !important;
  color: #000 !important;
}
::v-deep .k-btn.btn-size-4 {
  padding: 0 !important;
  background-color: var(--defaultprimarycolor) !important;
  font-size: 14px !important;
  // padding: 20px 18px !important;
  border: 0px;
  cursor: pointer;
  appearance: none;
  color: #fff !important;
  text-transform: uppercase;
  letter-spacing: 0.15em !important;
  line-height: 1.2em;
  display: inline-block;
  font-weight: 400 !important;
  transition: all 0.6s ease;
  text-align: center;
  user-select: none;
  margin-bottom: 35px;
  span.label {
    padding: 12px 20px !important;
    font-size: 14px;
    color: #fff !important;
    letter-spacing: 0em;
  }
  &:hover {
    background-color: #424242 !important;
    color: #fff !important;
  }
}
</style>
