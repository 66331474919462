import Vue from 'vue';
import VueRouter from 'vue-router';
import { globalStartupGuard, authGuard } from '../helpers/global/session-helper';
import OpenidLogin from '~/pages/auth/OpenidLogin.vue';

Vue.use(VueRouter);
const appRoutes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../layouts/dash-layout.vue'),
  },
  {
    path: '/dashboard',
    redirect: '/',
  },
  {
    path: '/report_builder',
    name: 'ReportBuilder',
    component: () => import(/* webpackChunkName: "report_builder" */ '~/pages/ReportBuilder.vue'),
  },
  {
    path: '/admin_settings',
    name: 'AdminSettings',
    component: () => import(/* webpackChunkName: "admin_settings" */ '~/pages/AdminSettings.vue'),
  },
];

const guestRoutes = [
  {
    path: '/login',
    redirect: (route) => {
      return {
        name: 'OpenidLogin',
        query: {
          app: route.query.app,
          mode: route.query.mode,
          error: route.query.error,
        },
      };
    },
  },
  {
    path: '/openid_login',
    name: 'OpenidLogin',
    component: OpenidLogin,
    props: (route) => {
      return {
        err: route.query.error,
        mode: route.query.mode,
      };
    },
  },
  {
    // Complete the user invite process.
    // After clicking 'accept' invite, user is prompted to complete their
    // details & set a password.
    path: '/create_password',
    name: 'CreatePassword',
    component: () => import('~/pages/auth/CreatePassword.vue'),
    props: (route) => {
      return {
        app: route.query.app,
        mode: route.query.mode,
        err: route.query.error,
        userId: route.query.userId,
        token: route.query.token,
        email: route.query.email,
        inputFullName: route.query.fullname,
      };
    },
  },
  {
    // Request a password reset
    path: '/forgot_password',
    name: 'ForgotPassword',
    component: () => import('~/pages/auth/ForgotPassword.vue'),
    props: (route) => {
      return {
        app: route.query.app,
        mode: route.query.mode,
        err: route.query.error,
      };
    },
  },
  {
    // Complete the password reset process
    path: '/reset_password',
    name: 'ResetPassword',
    component: () => import('~/pages/auth/ResetPassword.vue'),
    props: (route) => {
      return {
        app: route.query.app,
        mode: route.query.mode,
        err: route.query.error,
        userId: route.query.userId,
        token: route.query.token,
      };
    },
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('~/pages/auth/SignupUser.vue'),
    props: (route) => {
      return {
        app: route.query.app,
        mode: route.query.mode,
        err: route.query.error,
      };
    },
  },
];

guestRoutes.forEach((r) => {
  r.meta = { guest: true };
});

const router = new VueRouter({
  routes: [].concat(guestRoutes, appRoutes),
});

// Auth guard to be implemented
// Install router guard
router.beforeEach((to, from, next) => {
  globalStartupGuard(to, from).then(() => {
    authGuard(to, from, next);
  });
});

export default router;
