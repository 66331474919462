import Vue from 'vue';
import axios from 'axios';

import * as Sentry from '@sentry/vue';
import commonHelper, { isBlank } from 'adready-api/helpers/common';
import accountApi from 'adready-api/api/account';
import config from '~/config';

import forklift from '~/components/mixins/forklift-mixin';
import store from '~/store';
import {
  KEY_ACCOUNT_ID,
  KEY_ADVERTISER_ID,
  KEY_DEMO_ACCOUNT_ID,
  KEY_DEMO_ADVERTISER_ID,
  DEMO_ADVERTISERS,
  RANGE_CUSTOM,
  COMPARE_RANGE_PREV_DAY,
} from '~/constant';
import {
  convertEpochToNYTimezone,
  isDemoInstance,
  filterAccountsAndAdvertisersForDemo,
} from '~/util/utility-functions';
import layoutHelpers from './layout-helpers';
import { redirectToLogin } from './refresh-token';
import { getQueryParams } from '~/helpers/global/url-helpers';

const NEXT_URL = 'nextUrl';

// var to ensure that we run globalStartupGuard once and only once
let init;

// caches the jwt status
let hasToken = false;

/**
 * Get a valid account ID which the user has access to. If the user does not
 * have access to the given account ID, returns the first one from their list of
 * roles.
 *
 * @param {Array<UserAccount>} userAccounts
 * @param {Number} currentAccountId to test access for
 *
 * @returns {Account} valid account
 */
function getValidAccount(userAccounts, currentAccountId) {
  if (currentAccountId) {
    // try to find the current account to ensure we have access to it
    if (userAccounts) {
      let ua = userAccounts.find((userAccount) => userAccount.id === currentAccountId);
      if (!ua) {
        [ua] = userAccounts;
      }
      return ua;
    }
  }

  if (userAccounts && userAccounts.length > 0) {
    // was invalid or not passed in, default to first accountId we have access to
    const sortedList = commonHelper.caseInsensitiveSort([].concat(userAccounts), 'name');
    return sortedList[0];
  }

  return undefined;
}

/**
 * Get a valid advertiser ID which the user has access to. If the user does not
 * have access to the given advertiser ID, returns the first one from their list of
 * roles.
 *
 * @param {Account} currentAccount
 * @param {Number} currentAdvertiserId to test access for
 *
 * @returns {Advertiser} valid advertiser
 */
function getValidAdvertiser(currentAccount, currentAdvertiserId) {
  if (!Number.isNaN(currentAdvertiserId)) {
    // try to find the current account to ensure we have access to it
    if (currentAccount) {
      let ua = currentAccount.advertisers.find((adv) => adv.id === currentAdvertiserId);
      if (!ua) {
        const sortedList = commonHelper.caseInsensitiveSort(
          [].concat(currentAccount.advertisers),
          'name'
        );
        [ua] = sortedList;
      }
      return ua;
    }
  }

  if (currentAccount && currentAccount.advertisers && currentAccount.advertisers.length > 0) {
    // was invalid or not passed in, default to first advertiserId we have access to
    const sortedList = commonHelper.caseInsensitiveSort(
      [].concat(currentAccount.advertisers),
      'name'
    );
    return sortedList[0];
  }

  return undefined;
}

function fetchUserAccount(currentUserId, currentAccountId, currentAdvertiserId) {
  const key = 'common/userAccounts';
  const userAccounts = store.get(key);
  let retAccount = null;
  let retAdvertiser = null;
  const currentAccount = getValidAccount(userAccounts, currentAccountId);
  if (currentAccount) {
    retAccount = currentAccount;
    store.set('common/currentAccountId', currentAccount.id);
    localStorage.setItem(KEY_ACCOUNT_ID, currentAccount.id);
    store.set('common/account', currentAccount);

    const currentAdvertiser = getValidAdvertiser(currentAccount, currentAdvertiserId);
    if (currentAdvertiser) {
      retAdvertiser = currentAdvertiser;
      localStorage.setItem(KEY_ADVERTISER_ID, currentAdvertiser.id);
      store.set('common/advertiser', currentAdvertiser);
    }
  }
  return { account: retAccount, advertiser: retAdvertiser };
}

async function fetchAsyncUserAccount(currentUserId, currentAccountId, currentAdvertiserId) {
  const promises = [];
  promises.push(accountApi.userAccount(currentUserId));
  return Promise.all(promises).then((responses) => {
    const filteredUserAccounts = filterAccountsAndAdvertisersForDemo(responses[0]);
    let retAccount = null;
    let retAdvertiser = null;
    const currentAccount = getValidAccount(filteredUserAccounts, currentAccountId);
    if (currentAccount) {
      retAccount = currentAccount;
      store.set('common/currentAccountId', currentAccount.id);
      localStorage.setItem(KEY_ACCOUNT_ID, currentAccount.id);
      store.set('common/account', currentAccount);

      const currentAdvertiser = getValidAdvertiser(currentAccount, currentAdvertiserId);
      if (currentAdvertiser) {
        retAdvertiser = currentAdvertiser;
        localStorage.setItem(KEY_ADVERTISER_ID, currentAdvertiser.id);
        store.set('common/advertiser', currentAdvertiser);
      }
    }
    return { account: retAccount, advertiser: retAdvertiser };
  });
}

async function fetchDemoAccount(currentUserId, currentAccountId, currentAdvertiserId) {
  if (Number.isNaN(currentAccountId) || Number.isNaN(currentAdvertiserId)) {
    const ua = await fetchAsyncUserAccount(currentUserId, currentAccountId, currentAdvertiserId);
    currentAccountId = ua?.account?.id;
    currentAdvertiserId = ua?.advertiser?.id;
  }

  const keys = Object.keys(DEMO_ADVERTISERS).map((key) => parseInt(key, 10));
  const demoAdvertiserMapping = keys.includes(currentAdvertiserId)
    ? DEMO_ADVERTISERS[currentAdvertiserId]
    : DEMO_ADVERTISERS[0];

  const eDate = new Date();
  const dates = store.get('dashboard/dates');
  const updatedDates = {
    ...dates,
    startDate: convertEpochToNYTimezone(new Date(demoAdvertiserMapping.startDate)),
    endDate: demoAdvertiserMapping.endDate
      ? convertEpochToNYTimezone(new Date(demoAdvertiserMapping.endDate))
      : convertEpochToNYTimezone(new Date()),
    compareStartDate: convertEpochToNYTimezone(new Date(demoAdvertiserMapping.compareStartDate)),
    compareEndDate: demoAdvertiserMapping.compareEndDate
      ? convertEpochToNYTimezone(new Date(demoAdvertiserMapping.compareEndDate))
      : convertEpochToNYTimezone(eDate.setDate(eDate.getDate() - 1)),
    dateRangeOption: RANGE_CUSTOM,
    dateCompareOption: COMPARE_RANGE_PREV_DAY,
  };
  store.set('dashboard/dates', updatedDates);
  const mappedAccountId = parseInt(demoAdvertiserMapping.accountId, 10);
  const promises = [];
  promises.push(accountApi.userAccount(currentUserId));
  promises.push(accountApi.account(mappedAccountId));

  return Promise.all(promises).then((responses) => {
    let retAccount = null;
    let retAdvertiser = null;
    const userAccounts = filterAccountsAndAdvertisersForDemo(responses[0]);
    const mappedAccount = responses[1];
    const currentAccount = getValidAccount(userAccounts, currentAccountId);
    if (currentAccount && mappedAccount) {
      const currentAdvertiser = getValidAdvertiser(currentAccount, currentAdvertiserId);
      localStorage.setItem(KEY_DEMO_ACCOUNT_ID, currentAccount.id);
      localStorage.setItem(KEY_DEMO_ADVERTISER_ID, currentAdvertiser.id);
      store.set('common/demoSelectedAdvertiserName', currentAdvertiser?.name);

      const { logoFile, whitelabelEnabled, theme } = currentAccount.organization;

      mappedAccount.organization.logoFile = JSON.parse(JSON.stringify(logoFile));
      mappedAccount.organization.whitelabelEnabled = whitelabelEnabled;
      mappedAccount.organization.theme = JSON.parse(JSON.stringify(theme));
      retAccount = mappedAccount;
      const mappedAdvertiser = getValidAdvertiser(
        mappedAccount,
        demoAdvertiserMapping.advertiserId
      );
      retAdvertiser = mappedAdvertiser;
      store.set('common/currentAccountId', mappedAccount.id);
      localStorage.setItem(KEY_ACCOUNT_ID, mappedAccount.id);
      localStorage.setItem(KEY_ADVERTISER_ID, mappedAdvertiser.id);
      store.set('common/account', mappedAccount);
      store.set('common/advertiser', mappedAdvertiser);
    }
    return { account: retAccount, advertiser: retAdvertiser };
  });
}

function setCurrentAccount(currentUserId, accountId) {
  // prefer the accountId that was passed in, if not valid, use value stored in localStorage
  // accountId = 77;
  let currentAccountId = accountId;
  let currentAdvertiserId = 0;
  if (!(currentAccountId && !Number.isNaN(currentAccountId))) {
    const params = getQueryParams(window.location.href);
    if (params?.embed === 'true' && params.mode) {
      localStorage?.setItem('mode', params.mode);
    }
    const accountKeyName = isDemoInstance() ? KEY_DEMO_ACCOUNT_ID : KEY_ACCOUNT_ID;
    if (params?.embed === 'true' && params.accountId) {
      localStorage.setItem(accountKeyName, params.accountId);
    }
    currentAccountId = parseInt(localStorage.getItem(accountKeyName), 10);

    const advertiserKeyName = isDemoInstance() ? KEY_DEMO_ADVERTISER_ID : KEY_ADVERTISER_ID;
    if (params?.embed === 'true' && params.advertiserId) {
      localStorage.setItem(advertiserKeyName, params.advertiserId);
    }
    currentAdvertiserId = parseInt(localStorage.getItem(advertiserKeyName), 10);
  }

  if (isDemoInstance()) {
    return fetchDemoAccount(currentUserId, currentAccountId, currentAdvertiserId);
  }
  return fetchUserAccount(currentUserId, currentAccountId, currentAdvertiserId);
}

async function configurePendo() {
  // After calling secured-config api, we are setting flipPendoApiKey here.
  const configApiUrl = `${config.ADREADY_URL}/api/secured_config`;
  await axios
    .get(configApiUrl, { withCredentials: true })
    .then((res) => {
      config.PENDO_API_KEY ||= res.data.flipPendoApiKey;
      config.initializePendo();
    })
    .catch((e) => {
      console.error(`Failed to load ${configApiUrl}`, e);
    });
}

/**
 * Load user session from JWT
 *
 * Decodes the JWT to extract:
 * - User ID (subject aka sub)
 *
 * @returns {Boolean} true if successful (user has valid session)
 */
export async function loadSession(
  currentUserId,
  accountId,
  advertiserId,
  accountName,
  advertiserName,
  // eslint-disable-next-line no-unused-vars
  xandrAdvertiserId,
  to
) {
  // read user ID from the token

  // if token does not have currentUserId, fetch it
  if (!currentUserId) {
    let decoded;
    try {
      decoded = await axios.get(`${config.ADREADY_URL}/api/token/parse`, { withCredentials: true });
      decoded = decoded?.data?.result;
    } catch (err) {
      return false;
    }

    if (!decoded) {
      return false;
    }

    currentUserId = decoded.userId;
  }

  store.set('common/currentUserId', currentUserId);
  Sentry.setUser({ id: currentUserId.toString() });
  if (Vue.StackdriverErrors && !Number.isNaN(currentUserId) && currentUserId > 0) {
    // User must be a string
    Vue.StackdriverErrors.setUser(currentUserId.toString());
  }

  // Pendo setup
  await configurePendo();

  // load current user
  // these are currently fire-and-forget calls
  // as this data is not needed in the hot startup path
  forklift.methods.loadCurrentUser(currentUserId, true).then((user) => {
    const email = user && user.email ? user.email.toLowerCase() : '';
    Sentry.setUser({ id: currentUserId.toString(), email });
    if (config?.pendoEnabled?.() && accountName && advertiserName) {
      const theEnv = config.ENV_NAME ? config.ENV_NAME : 'Unknown';
      try {
        window?.pendo?.initialize({
          visitor: {
            env: theEnv,
            id: currentUserId,
            email,
          },
          account: {
            id: advertiserName,
            advertiser: advertiserName,
            account: accountName,
          },
        });
      } catch (error) {
        console.error('Error initializing Pendo user:', error);
      }
    }
  });
  forklift.methods.loadCurrentUserRoles(currentUserId, true);
  // load user's accounts, current account, advertisers for current account, & roles
  forklift.methods.loadUserAccounts(currentUserId).then(async () => {
    // load account roles
    const { account, advertiser } = await setCurrentAccount(currentUserId, null);

    if (to?.name !== 'AdminSettings') {
      Promise.all([
        forklift.methods.loadFlipPixels(advertiser),
        forklift.methods.loadAdvertiserGroupings(true, account, advertiser),
        forklift.methods.loadEventOptions(true, account, advertiser),
        forklift.methods.loadMediaTypes(true, account, advertiser),
      ])
        .then(() => store.set('common/dashboardLoading', false))
        .catch((error) => console.log(error));
    }
    return true;
  });

  return true;
}

/**
 * Router guard which validates that the user is properly authenticated to
 * access the given 'to' route.
 *
 */
export function authGuard(to, from, next) {
  if (to.path === '/openid_login') {
    next();
  } else if (to.meta.guest === true) {
    next();
  } else if (!hasToken) {
    // everything else requires user to be logged in
    localStorage.setItem(NEXT_URL, to.fullPath);
    // next({ name: 'Login' });
    const { mode } = to.query;
    redirectToLogin(mode);
  } else {
    // allow authenticated user to continue
    const nextUrl = localStorage.getItem(NEXT_URL);
    if (!isBlank(nextUrl)) {
      // have a stored redirect url, use it
      localStorage.removeItem(NEXT_URL);
      next(nextUrl);
    } else {
      // just continue on to the requested route.
      // this is the most common scenario for a logged-in user.
      next();
    }
  }
}

/**
 * Global router guard which runs exactly *once*, when the application is first
 * loaded. That is, when processing the very first route. Anything which needs
 * to run at the earliest possible time, before loading any Vue components
 * should happen here.
 *
 * Note, however, that by this point, the route is already selected, however it
 * can still be modified (by passing the next func from the guard method below).
 *
 * Currently it:
 * - loads theme
 * - loads layout
 * - handles the AuthN check for secure routes (reading the JWT)
 * - attempts to bust caches on version changes
 */
export async function globalStartupGuard(to) {
  if (hasToken === false) {
    // always reload token first, when not logged in
    hasToken = await loadSession(
      parseInt(to.query.currentUserId, 10),
      parseInt(to.query.accountId, 10),
      parseInt(to.query.advertiserId, 10),
      to.query.accountName,
      to.query.advertiserName,
      to.query.xandrAdvertiserId,
      to
    );
  }

  window.document.title = config.FLIP_NAME || 'Flip';

  if (init !== undefined) {
    return init;
  }

  // load theme
  layoutHelpers.updateTheme();

  if (!hasToken) {
    init = false;
    return false;
  }
  return true;
}
