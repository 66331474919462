import _ from 'underscore';
import { Plan } from 'adready-api/model/plan';
import commonHelper, { inert, isBlank } from 'adready-api/helpers/common';
import accountApi from 'adready-api/api/account';
import { ACCOUNT_FLAG_MNI, ACCOUNT_MNI_PREFIX_MERD, IOS_CHANGE_DRAFT } from 'adready-api/constant';
import usersApi, { userRolesApi } from 'adready-api/api/user';
import organizationApi from 'adready-api/api/organization';
import planApi from 'adready-api/api/plans';
import authz from 'adready-api/authz';
import store from '~/store';
import { dedupe } from '~/components/mixins/forklift/dedupe';
import advertiserReportsAPI from '~/api/advertiser-reports';
import flipPixelApi from '~/api/flipPixel';
import { buildQueryString } from '~/helpers/global/url-helpers';
import liStatsApi from '~/api/liStats';
import {
  DEMO_ADVERTISERS,
  KEY_DEMO_ADVERTISER_ID,
  PAGINATION_DEFAULT_ITEM_PER_PAGE,
} from '~/constant';
import { isDemoInstance, isFlamingoInstance } from '~/util/utility-functions';

const accountLoaders = {
  /**
   * Checks if the given data is available (already loaded and in the store)
   * @param {String} key
   */
  isDataLoaded(key) {
    const data = store.get(key);
    return data && !_.isEmpty(data);
  },

  async loadFlipPixels(pAdvertiser) {
    const key = 'common/universalPixelId';

    if (!pAdvertiser || !pAdvertiser.xandrAdvertiserId) {
      store.set(key, null);
      return;
    }
    flipPixelApi
      .flipPixels(pAdvertiser.id)
      .then((pixels) => {
        if (pixels && _.isArray(pixels)) {
          const universalPixel = pixels.find((p) => p.conversion_type === 'Universal');
          store.set(key, universalPixel ? universalPixel.flip_id : null);
        }
      })
      .catch(() => {
        store.set(key, null);
      });
  },

  async loadAdvertiserGroupings(force, pAccount, pAdvertiser) {
    const key = 'dashboard/filters@ioOptions';
    if (!force && this.isDataLoaded(key)) {
      return Promise.resolve(store.get(key)); // nothing to do, bail out
    }

    if (
      !pAccount ||
      !pAdvertiser ||
      isBlank(pAccount.name) ||
      isBlank(pAdvertiser.name) ||
      isBlank(pAdvertiser.xandrAdvertiserId)
    ) {
      return [];
    }

    return dedupe(key, () => {
      return advertiserReportsAPI
        .ioNames(
          pAdvertiser.id,
          buildQueryString({
            advertiser: pAdvertiser.name,
            client: pAccount.name,
          })
        )
        .then(async (data) => {
          if (data.length !== 0) {
            if (isDemoInstance()) {
              const advertiserKeys = Object.keys(DEMO_ADVERTISERS).map((k) => parseInt(k, 10));
              const demoAdvertiserId = parseInt(localStorage.getItem(KEY_DEMO_ADVERTISER_ID), 10);
              const demoAdvertiserMapping = advertiserKeys.includes(demoAdvertiserId)
                ? DEMO_ADVERTISERS[demoAdvertiserId]
                : DEMO_ADVERTISERS[0];
              for (let i = 0; i < data.length; i++) {
                if (demoAdvertiserMapping.selectedCampaign.includes(data[i].value)) {
                  data[i].checked = true;
                } else {
                  data[i].checked = false;
                }
              }
            } else {
              for (let i = 0; i < data.length; i++) {
                data[i].checked = data[i].isRecent;
                data[i].disabled = false;
              }
            }
            const adGroupData = await advertiserReportsAPI.adGroupOptions(
              pAdvertiser.id,
              buildQueryString({ includeNonFlip: isFlamingoInstance() })
            );
            if (adGroupData?.data?.length > 0) {
              for (let j = 0; j < adGroupData.data.length; j++) {
                adGroupData.data[j].checked = true;
                adGroupData.data[j].value = adGroupData.data[j].lineItemName;
              }
              store.set('dashboard/filters@adGroupOptions', adGroupData.data);
            }
            store.set(key, data);
            return data;
          }
          store.set(key, []);
          store.set('dashboard/filters@adGroupOptions', []);
          return [];
        })
        .catch((e) => this.forkliftErrHandler(e, 'groupings'));
    });
  },

  async loadEventOptions(force, pAccount, pAdvertiser) {
    const key = 'dashboard/filters@eventOptions';
    if (!force && this.isDataLoaded(key)) {
      return Promise.resolve(store.get(key)); // nothing to do, bail out
    }

    if (!pAccount || !pAdvertiser) {
      return [''];
    }

    return dedupe(key, () => {
      return advertiserReportsAPI
        .events(
          pAdvertiser.id,
          buildQueryString({
            advertiser: pAdvertiser.name,
            client: pAccount.name,
            xandrId: pAdvertiser ? pAdvertiser.xandrAdvertiserId : '',
          })
        )
        .then((data) => {
          data.forEach((element) => {
            element.open = element.selected; // TODO: Amit to review (2 out of 2)
          });
          store.set(key, data);
        })
        .catch((e) => this.forkliftErrHandler(e, 'eventOptions'));
    });
  },

  async loadMediaTypes(force, pAccount, pAdvertiser) {
    const key = 'dashboard/filters@mediaTypeOptions';
    if (!force && this.isDataLoaded(key)) {
      return Promise.resolve(store.get(key)); // nothing to do, bail out
    }

    if (!pAccount || !pAdvertiser) {
      return [];
    }

    return dedupe(key, () => {
      return advertiserReportsAPI.mediaTypes(
        pAdvertiser.id,
        buildQueryString({
          advertiser: pAdvertiser.name,
          client: pAccount.name,
          includeNonFlip: isFlamingoInstance(),
        })
      );
    })
      .then((data) => {
        if (data && data.length > 0) {
          const newData = [];
          data.forEach((element) => {
            newData.push({ value: element, checked: true });
          });
          store.set(key, newData);
          return data;
        }
        store.set(key, []);
        return [];
      })
      .catch((e) => this.forkliftErrHandler(e, 'mediaTypes'));
  },

  async loadAccount(accountId, force) {
    // if (!accountId || accountId === ALL_PARTNER_ACCOUNT_ID) {
    //   return [];
    // }
    const key = `common/accountMap@${accountId}`;
    if (!force && this.isDataLoaded(key)) {
      return Promise.resolve(store.get(key)); // nothing to do, bail out
    }
    return dedupe(key, () => {
      return accountApi
        .account(accountId)
        .then((res) => {
          const currentUser = store.get('common/currentUser');
          if (
            currentUser &&
            authz.isAccountManager(currentUser.role.name) &&
            res.hasFlag(ACCOUNT_FLAG_MNI)
          ) {
            res.name = `${ACCOUNT_MNI_PREFIX_MERD} - ${res.name}`;
          }
          store.set(key, res);
          return res;
        })
        .catch((e) => this.forkliftErrHandler(e, 'account'));
    });
  },

  async loadUserAccounts(userId, force) {
    const key = 'common/userAccounts';
    if (!force && this.isDataLoaded(key)) {
      return Promise.resolve(store.get(key)); // nothing to do, bail out
    }
    return dedupe(key, () => {
      return accountApi
        .userAccount(userId)
        .then((res) => {
          const accounts = res.map((a) => {
            const currentUser = store.get('common/currentUser');
            if (
              currentUser &&
              authz.isAccountManager(currentUser.role.name) &&
              a.hasFlag(ACCOUNT_FLAG_MNI)
            ) {
              a.name = `${ACCOUNT_MNI_PREFIX_MERD} - ${a.name}`;
              return a;
            }
            return a;
          });
          const sortedAccounts = commonHelper.caseInsensitiveSort(accounts, 'name');
          const uniqAccounts = _.uniq(sortedAccounts, true, (a) => a.id);
          store.set(key, uniqAccounts);
          return res;
        })
        .catch((e) => this.forkliftErrHandler(e, 'userAccounts'));
    });
  },

  async loadAccounts(force) {
    const key = 'common/accounts';
    if (!force && this.isDataLoaded(key)) {
      return Promise.resolve(store.get(key)); // nothing to do, bail out
    }
    return dedupe(key, () => {
      return accountApi
        .accountAll()
        .then((res) => {
          const accounts = res.map((a) => {
            const currentUser = store.get('common/currentUser');
            if (
              currentUser &&
              authz.isAccountManager(currentUser.role.name) &&
              a.hasFlag(ACCOUNT_FLAG_MNI)
            ) {
              a.name = `${ACCOUNT_MNI_PREFIX_MERD} - ${a.name}`;
              return a;
            }
            return a;
          });
          const sortedAccounts = commonHelper.caseInsensitiveSort(accounts, 'name');
          store.set(key, sortedAccounts);
          return res;
        })
        .catch((e) => this.forkliftErrHandler(e, 'userAccounts'));
    });
  },

  async loadCurrentUser(userId, force) {
    const key = 'common/currentUser';
    if (!force && this.isDataLoaded(key)) {
      return Promise.resolve(store.get(key)); // nothing to do, bail out
    }
    return dedupe(key, () => {
      return usersApi
        .read(userId)
        .then((res) => {
          store.set(key, res);
          return res;
        })
        .catch((e) => this.forkliftErrHandler(e, 'currentUser'));
    });
  },

  async loadCurrentUserRoles(userId, force) {
    const key = 'common/currentUserRoles';
    if (!force && this.isDataLoaded(key)) {
      return Promise.resolve(store.get(key)); // nothing to do, bail out
    }
    return dedupe(key, () => {
      return userRolesApi
        .globalRoles(userId)
        .then((res) => {
          const currentUserRoles = res.data;
          if (currentUserRoles) {
            const role = currentUserRoles[0];
            store.set(key, role.name);
          }
          return res;
        })
        .catch((e) => this.forkliftErrHandler(e, 'currentUserRoles'));
    });
  },

  async loadUserAccountRoles(userId, force) {
    const key = 'common/userAccountRoles';
    if (!force && this.isDataLoaded(key)) {
      return Promise.resolve(store.get(key)); // nothing to do, bail out
    }
    return dedupe(key, () => {
      return accountApi
        .userAccountRoles(userId)
        .then((res) => {
          store.set(key, res);
          res.forEach((a) => {
            store.set(
              `common/accountRolesMap@${a.accountId}`,
              authz.createRoleMap(res, a.accountId)
            );
          });
          return res;
        })
        .catch((e) => this.forkliftErrHandler(e, 'userAccounts'));
    });
  },

  loadUsers(accountId, force) {
    // if (!accountId || accountId === ALL_PARTNER_ACCOUNT_ID) {
    //   return [];
    // }
    const key = `common/accountUsersMap@${accountId}`;
    if (!force && this.isDataLoaded(key)) {
      return Promise.resolve(store.get(key)); // nothing to do, bail out
    }
    return dedupe(key, () => {
      return usersApi
        .index({ accountId })
        .then((users) => {
          store.set(key, users);
          return users;
        })
        .catch((e) => this.forkliftErrHandler(e, 'users'));
    });
  },

  loadAllUsers(force) {
    const key = 'common/allUsers';
    if (!force && this.isDataLoaded(key)) {
      return []; // nothing to do, bail out
    }
    return dedupe(key, () => {
      return usersApi
        .index({})
        .then((users) => {
          const sortedUser = _.sortBy(users, (u) => u.id).reverse();
          store.set(key, sortedUser);

          return sortedUser;
        })
        .catch((e) => this.forkliftErrHandler(e, 'users'));
    });
  },

  loadAllUsersForCurrentUser(userId, force) {
    const key = 'common/allUsersForCurrentUser';
    if (!force && this.isDataLoaded(key)) {
      return Promise.resolve(store.get(key));
    }
    return dedupe(key, () => {
      return usersApi
        .index({ userId })
        .then((users) => {
          const sortedUser = _.sortBy(users, (u) => u.id).reverse();
          store.set(key, sortedUser);
          return sortedUser;
        })
        .catch((e) => this.forkliftErrHandler(e, 'users'));
    });
  },

  loadAllOrganizations(force) {
    const key = 'common/allOrganizations';
    if (!force && this.isDataLoaded(key)) {
      return []; // nothing to do, bail out
    }
    return dedupe(key, () => {
      return organizationApi
        .index({})
        .then((org) => {
          const sortedOrg = _.sortBy(org, (u) => u.name);
          store.set(key, sortedOrg);

          return sortedOrg;
        })
        .catch((e) => this.forkliftErrHandler(e, 'organizations'));
    });
  },

  loadAccountsData(accountId, force) {
    this.loadAccount(accountId, force);
    return true;
  },

  /**
   * this function returns the updated list by adding the CR Plans just after to its parent LineItem.
   */
  addCRLinesToList(plans) {
    if (isBlank(plans)) {
      return plans;
    }
    const newPlans = [];
    plans.forEach((p) => {
      newPlans.push(p);
      if (!isBlank(p.crPlans)) {
        const crPlans = p.crPlans
          .map((cr) => new Plan(cr))
          .filter((cr) => cr.statusId === IOS_CHANGE_DRAFT);
        // There should be only one active CR at a point of time, add first one to the list.
        if (!isBlank(crPlans)) {
          newPlans.push(crPlans[0]);
        }
      }
    });
    return newPlans;
  },

  loadPlansData(q, opts, flip, force, includePacing, advertiser, client, xandrId, pixel) {
    const key = 'common/plans';
    if (!force && this.isDataLoaded(key)) {
      return store.get(key); // nothing to do, bail out
    }
    store.dispatch('common/setPlans', { content: [] });
    if (!opts) {
      opts = {};
    }

    const pageState = store.get('common/orderCriteria@page');
    opts.pageNumber = pageState.num || 0;
    opts.perPage = pageState.per || PAGINATION_DEFAULT_ITEM_PER_PAGE;
    const crFilter = false;
    store.set('common/plansQuery', inert(store.get('common/orderCriteria')));
    return dedupe(key, () => {
      return planApi
        .index({ q, ...opts, crFilter, flip, includePacing, advertiser, client, xandrId, pixel })
        .then((response) => {
          store.dispatch('common/setPlans', response);
          return response.content;
        })
        .catch((e) => this.forkliftErrHandler(e, 'plans'));
    });
  },
  loadLineItemStatusCount(accountId, advertiserId, isFlip, force) {
    const key = 'common/liStatusCount';
    if (!force && this.isDataLoaded(key)) {
      return []; // nothing to do, bail out
    }
    const randomNumber = Math.random();
    return dedupe(randomNumber, () => {
      return liStatsApi
        .lineItemStatusCount({
          accountId,
          advertiserId,
          isFlip,
          randomNumber,
        })
        .then((liStatusCount) => {
          const { data } = liStatusCount;
          store.set(key, data);
          return data;
        })
        .catch((e) => this.forkliftErrHandler(e, 'liStatusCount'));
    });
  },
  loadLineItemStatusCountForPlans(accountId, advertiserId, isFlip, force) {
    const key = 'common/liStatusCountForPlans';
    if (!force && this.isDataLoaded(key)) {
      return []; // nothing to do, bail out
    }
    const randomNumber = Math.random();
    const planIdForLineItemStatusCountLoading = 'common/planIdForLineItemStatusCountLoading';
    return dedupe(randomNumber, () => {
      return liStatsApi
        .lineItemStatusCountForPlans({
          accountId,
          advertiserId,
          isFlip,
          randomNumber,
        })
        .then((liStatusCountForPlans) => {
          const { data } = liStatusCountForPlans;
          store.set(key, data);
          store.set(planIdForLineItemStatusCountLoading, null);
          return data;
        })
        .catch((e) => this.forkliftErrHandler(e, 'liStatusCountForPlans'));
    });
  },
};

export default accountLoaders;
