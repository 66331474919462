<template>
  <div class="wrapper">
    <div v-if="!verifyEmailPayload.show" class="flip-container">
      <div class="login-screen">
        <span class="main-logo" :style="{ backgroundImage: `url(${config.SITE_LOGO_URL})` }"></span>
        <div class="login-block row middle-xs">
          <span
            class="loginimg"
            :style="{ backgroundImage: `url(${config.LOGIN_PAGE_IMAGE_URL})` }"
          ></span>
          <div class="login-info">
            <span class="ftitle" @click="loginLabelClicked()">Login</span>
            <label class="flabel">Email</label>
            <k-text-input
              ref="email"
              class="login-input"
              :required="false"
              :autofocus="true"
              @change="onChangeEmail"
              @validation="onValidation"
              @keyup="onKeyUp"
            />
            <label class="flabel">Password</label>
            <k-text-input
              ref="password"
              class="login-input"
              :required="false"
              :default-focus="true"
              type="password"
              @change="onChangePassword"
              @validation="onValidation"
              @keyup="onKeyUp"
            />
            <k-link
              class="forgot-password"
              :to="{ path: '/forgot_password?app=' + appId + '&mode=' + mode }"
            >
              Forgot Password
            </k-link>

            <k-button :size="4" label="Login" @click="onClickLogin" />
            <div
              v-if="config.GOOGLE_SIGN_IN"
              id="orBeforeGoogleSignin"
              class="login-input mg-tp-20 or"
            >
              OR
            </div>

            <GoogleSignInButton
              v-if="!isFlipPlus() && config.GOOGLE_SIGN_IN"
              id="googleSignInButton"
              :o-auth-url-host="`${config.ADREADY_URL}`"
              class="plain"
              @click="onGoogleSignIn"
            />

            <k-link
              v-if="isFlipPlus()"
              class="signup-label"
              :to="{ path: '/signup?app=' + appId + '&mode=' + mode }"
            >
              Don't have an account?
              <p class="signup-text">Sign Up</p>
            </k-link>
          </div>
          <div v-if="error" class="error">Login Error: {{ error }}</div>
        </div>
      </div>
    </div>
    <div v-if="verifyEmailPayload.show">
      <verify-email
        :is-from-login="true"
        :email="verifyEmailPayload.email"
        :user-id="verifyEmailPayload.userId"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { isBlank } from 'adready-api/helpers/common';
import validationsMixin from 'adready-vue/components/mixins/validations-mixin';
import GoogleSignInButton from 'adready-vue/components/google-sign-in-button.vue';
import VueCookies from 'vue-cookies';
import { COOKIE_NAME_REDIRECT_URI } from 'adready-api/helpers/cookie-session-helper';
import VerifyEmail from './VerifyEmail.vue';
import config from '~/config';

export default {
  components: {
    GoogleSignInButton,
    VerifyEmail,
    KLink: () =>
      import(/* webpackChunkName: "k-link" */ 'adready-vue/components/elements/k-link.vue'),
  },

  mixins: [validationsMixin.events],
  props: {
    err: {
      required: false,
      type: String,
      default: undefined,
    },
    mode: {
      required: false,
      type: String,
      default: '',
    },
  },

  data() {
    return {
      login: {},
      errorMsg: null,
      appId: null,
      verifyEmailPayload: { show: false },
      config,
      shiftPressed: false,
    };
  },

  computed: {
    error() {
      if (!isBlank(this.errorMsg)) {
        return this.errorMsg;
      }
      if (!isBlank(this.err)) {
        return this.err;
      }
      return null;
    },
  },

  mounted() {
    // clear cached IDs
    window.localStorage?.removeItem('plan/campaignId');
    window.localStorage?.removeItem('plan/planId');
    window.localStorage?.removeItem('campaignId');

    window.addEventListener('keydown', this.checkKeyDown);
    window.addEventListener('keyup', this.checkKeyUp);
  },

  created() {
    this.appId = this.$route.query.app || 4;
    localStorage?.setItem('mode', this.mode);
  },
  methods: {
    checkKeyDown(event) {
      if (event.key === 'Shift') {
        this.shiftPressed = true;
      }
    },
    checkKeyUp(event) {
      if (event.key === 'Shift') {
        this.shiftPressed = false;
      }
    },
    loginLabelClicked() {
      if (this.shiftPressed) {
        this.$set(this.config, 'GOOGLE_SIGN_IN', '1');
      }
    },
    isFlipPlus() {
      return this.mode && this.mode === 'fp';
    },
    onChangeEmail(val) {
      // update text directly
      this.login.email = val;
    },

    onChangePassword(val) {
      // update text directly
      this.login.password = val;
    },

    onKeyUp(val, e) {
      if (e.key && e.key.includes('Enter')) {
        this.onClickLogin();
      }
    },

    async onClickLogin() {
      // remove the 'redirect_uri' cookie which has been setup on full domain url.
      VueCookies.remove(COOKIE_NAME_REDIRECT_URI);
      if (isBlank(this.login.email)) {
        this.errorMsg = 'Email/password not set';
        this.$refs.email.focus();
        return;
      }
      if (isBlank(this.login.password)) {
        this.errorMsg = 'Email/password not set';
        this.$refs.password.focus();
        return;
      }
      this.errorMsg = null;
      try {
        const success = await axios.post(
          `${config.ADREADY_URL}/api/auth/login?app=${this.appId}`,
          this.login,
          {
            withCredentials: true,
          }
        );
        if (success) {
          window.location = `${config.FLIP_URL}`;
        }
      } catch (error) {
        if (error?.response?.status === 400) {
          this.errorMsg = 'Bad Request, Please contact support ';
        } else if (error?.response?.status === 401) {
          const msg = error.response.data.message;
          if (isBlank(msg)) {
            this.errorMsg = 'You are not authorised to access the application';
          } else {
            this.errorMsg = msg;
            if (msg.includes('disabled')) {
              this.errorMsg += '. Please contact support.';
            } else if (msg.includes('verified')) {
              const userId = error.response.data.result;
              this.verifyEmailPayload = { show: true, email: this.login.email, userId };
              this.errorMsg += '. Please verify your email address.';
            }
          }
        } else {
          this.errorMsg = 'error processing login';
          // eslint-disable-next-line no-console
          console.error(this.errorMsg, error);
          if (this.$sentry) {
            if (error._reported !== true) {
              this.$sentry.captureException(error);
              error._reported = true;
            }
          }
        }
      }
    },
    onGoogleSignIn() {
      VueCookies.set(COOKIE_NAME_REDIRECT_URI, config.FLIP_URL, null, null, config.COOKIE_DOMAIN);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex: 1;
}
.flip-container {
  background-color: #131619;
  width: 100vw !important;
  height: 100vh;
  margin-bottom: -40px;
}
.or {
  color: #9c9c9c !important;
  text-align: center;
}
.forgot-password {
  margin-top: -12px;
  margin-bottom: 20px;
  font-size: smaller;
  color: #9c9c9c !important;
  text-align: right;
}
.signup-text {
  color: var(--defaultprimarycolor);
  font-size: 15px;
  margin-left: 5px;
}
.signup-label {
  display: flex;
  font-size: 15px;
  margin-top: 25px;
  color: #9c9c9c !important;
}
.plain {
  margin-left: 0px !important;
}
::v-deep .form-input .form-input-field {
  width: 100%;
  padding: 15px 20px;
  box-sizing: border-box;
  font-size: 12px;
  border: 1px solid #dadada !important;
  background-color: #fff !important;
  color: #000 !important;
}
::v-deep .k-btn.btn-size-4 {
  padding: 0 !important;
  background-color: var(--defaultprimarycolor) !important;
  font-size: 14px !important;
  // padding: 20px 18px !important;
  border-radius: 0 !important;
  border: 0px;
  cursor: pointer;
  appearance: none;
  color: #fff !important;
  text-transform: uppercase;
  letter-spacing: 0.15em !important;
  line-height: 1.2em;
  display: inline-block;
  font-weight: 400 !important;
  transition: all 0.6s ease;
  text-align: center;
  min-width: 214px !important;
  user-select: none;
  span.label {
    padding: 14px 22px !important;
  }
  &:hover {
    background-color: #424242 !important;
    color: #fff !important;
  }
}
</style>
