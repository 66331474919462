import moment from 'moment';
import axios from 'axios';
import { isBlank } from 'adready-api/helpers/common';
import VueCookies from 'vue-cookies';
import { COOKIE_NAME_REDIRECT_URI } from 'adready-api/helpers/cookie-session-helper';
import config from '~/config';
import { APP_FLIP_ID, APP_CONSOLE_ID } from '~/constant';
import { getQueryParams } from '~/helpers/global/url-helpers';

// this is the time to trigger the refresh token before auth token expiry i.e. 1 min
const timeIntervalInExpiry = -1;

const DEFAULT_REFRESH_TOKEN_TIME = 5340000; // default time is 89 minutes (5340000 milliseconds)
/**
 * It will redirect to flip openid login.
 */
export function redirectToLogin(mode) {
  const redirectUrl = config.FLIP_URL;
  const modeStr = mode ? `&mode=${mode}` : '';

  if (!config || isBlank(config.COOKIE_DOMAIN)) {
    // bail out with invalid config or else we could get stuck in a redirect loop,
    // either when trying to forward to openid login or when returning if cookie
    // is not set correctly.
    console.error('config is not valid (missing COOKIE_DOMAIN). cannot continue', config);
    return;
  }
  VueCookies.set(COOKIE_NAME_REDIRECT_URI, redirectUrl, null, null, config.COOKIE_DOMAIN);
  const params = getQueryParams(window.location.href);
  if (params?.embed === 'true' && window.parent) {
    window.parent.postMessage(
      {
        type: 'embedLogout',
        url: `${config.APP_URL}/#/openid_login?app=${APP_CONSOLE_ID}${modeStr}`,
      },
      '*'
    );
  } else {
    window.location = `/#/openid_login?app=${APP_FLIP_ID}${modeStr}`;
    window.location.reload();
  }
}

const uuid = (a) =>
  a // eslint-disable-next-line no-bitwise
    ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uuid);

let timerId = null;

function getRefreshTime(expiryTime) {
  //  console.log(expiryTime);
  const currentTimeStamp = moment().valueOf();
  // console.log(`current time : ${currentTimeStamp}`);
  const refreshMoment = moment(expiryTime).add(timeIntervalInExpiry, 'minutes');
  const refreshTimeStamp = refreshMoment.valueOf();
  // console.log(`refresh time : ${refreshTimeStamp}`);
  let diff = refreshTimeStamp - currentTimeStamp;
  diff = diff > 0 ? diff : 10;
  diff = diff > DEFAULT_REFRESH_TOKEN_TIME ? DEFAULT_REFRESH_TOKEN_TIME : diff;
  return diff;
}

function refreshTokenTrigger(timeOut) {
  timerId = setTimeout(() => {
    // console.log('Function is called', timeOut);
    // clear the previous timer
    if (timerId !== null) {
      clearTimeout(timerId);
    }
    // call the refresh token API and set the
    axios
      .get(`${config.ADREADY_URL}/api/token/refresh`, { withCredentials: true })
      .then((refreshedToken) => {
        // console.log(refreshedToken);
        if (refreshedToken?.data?.result) {
          const refreshTime = getRefreshTime(refreshedToken.data.result.expires);
          refreshTokenTrigger(refreshTime);
        }
      })
      .catch(() => {
        axios.delete(`${config.ADREADY_URL}/api/token/remove`, { withCredentials: true });
        const mode = localStorage.getItem('mode');
        redirectToLogin(mode);
      });
    return uuid();
  }, timeOut);
}

export function initTimer(expiryTime) {
  const refreshTime = getRefreshTime(expiryTime);
  refreshTokenTrigger(refreshTime);
}

export default { refreshTokenTrigger };
